<template>
  <b-card>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <data-table
      v-else
      :columns="columns"
      :rows.sync="dayData"
      :settings="settings"
      :dev-logs="false"
      @updateFilter="doUpdateFilter"
    >
      <template v-slot:header-button>
        <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          class="mr-1"
          right
          variant="secondary"
          text="Фильтр"
        >
          <b-dropdown-form class="py-1">
            <b-form-group
              label="Организация"
              label-for="dropdown-form-name"
              @submit.prevent.stop
            >
              <b-form-select
                v-model="filters.organization_id"
                :options="organizations"
                value-field="id"
                text-field="name"
              />
            </b-form-group>
            <b-form-group
              label="Тип"
              label-for="dropdown-form-name"
              @submit.prevent.stop
            >
              <b-form-select
                v-model="filters.type_kkm"
                :options="[{id: null, name: ' — '},{id: 1, name: 'Расход'},{id: 2, name: 'Приход'}]"
                value-field="id"
                text-field="name"
              />
            </b-form-group>
            <b-form-group
              v-if="filters.type_kkm !== null"
              label="Тип операции"
              label-for="dropdown-form-name"
              @submit.prevent.stop
            >
              <b-form-select
                v-model="filters.detail_id"
                :options="availableDetails"
                value-field="id"
                text-field="name"
              />
            </b-form-group>
            <b-form-group
              label="Кол-во на странице"
              label-for="dropdown-form-per-page"
              @submit.prevent.stop
            >
              <b-form-select
                id="dropdown-form-per-page"
                v-model="filters.perPage"
                size="sm"
                :options="[10, 20, 50, 100, 500]"
              />
            </b-form-group>

            <b-button-group>
              <b-button
                variant="primary"
                size="sm"
                @click="doApplyFilters"
              >
                Применить
              </b-button>
              <b-button
                variant="outline-secondary"
                size="sm"
                @click="doResetFilters"
              >
                Сбросить
              </b-button>
            </b-button-group>
          </b-dropdown-form>
        </b-dropdown>
        <router-link :to="{name: 'kassa-create'}">
          <b-button variant="primary">
            Создать
          </b-button>
        </router-link>

      </template>

      <template v-slot:table-row="item">

        <span
          v-if="item.column.field === 'type_kkm'"
          class="font-weight-bolder"
        >
          <span
            v-if="item.row.type_kkm === 1"
            class="text-danger"
          >Расход</span>
          <span
            v-else-if="item.row.type_kkm === 2"
            class="text-success"
          >Приход</span>
        </span>

        <span v-else-if="item.column.field === 'type_operation'">
          <span>{{ item.row.type_operation }}</span><br>
          <span><small>{{ item.row.about }}</small></span>
        </span>

        <span
          v-else-if="item.column.field === 'sum'"
          class="font-weight-bolder"
        >
          <span
            v-if="item.row.type_kkm === 1"
            class="text-danger"
          >-{{ new Intl.NumberFormat('ru-RU').format(item.row.sum) }}₽</span>
          <span
            v-if="item.row.type_kkm === 2"
            class="text-success"
          >+{{ new Intl.NumberFormat('ru-RU').format(item.row.sum) }}₽</span>
        </span>

        <span
          v-else-if="item.column.field === 'action'"
          class="float-right"
        >
          <!--          <b-button-group>-->
          <router-link
            class="mr-50"
            :to="{name: 'kassa-update', params: {id: item.row.id}}"
          >
            <b-button
              variant="flat-warning"
              class="btn-icon"
            ><feather-icon
              icon="EditIcon"
            />
            </b-button>
          </router-link>
          <b-button
            variant="flat-danger"
            class="btn-icon"
            @click="objectDelete(item.row.id)"
          >
            <feather-icon
              icon="Trash2Icon"
              class="text-danger"
            />
          </b-button>
          <!--          </b-button-group>-->
        </span>
      </template>
    </data-table>
  </b-card>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BDropdown,
  BDropdownForm,
  BFormGroup,
  BFormSelect,
  BSpinner,
} from 'bootstrap-vue'
import DataTable from '@/components/DataTable.vue'
import axiosIns from '@/libs/axios'
import _ from 'lodash'
import store from '@/store'

export default {
  name: 'KassaShowDay',
  components: {
    DataTable,
    BCard,
    BSpinner,
    BDropdown,
    BButton,
    BButtonGroup,
    BFormSelect,
    BFormGroup,
    BDropdownForm,
  },
  data() {
    return {
      columns: [
        {
          label: 'Организация',
          field: row => _.find(this.organizations, org => org.id === row.organization_id).name,
        },
        {
          label: 'Тип',
          field: 'type_kkm',
          // field: row => (row.type_kkm === 1 ? 'Расход' : 'Приход'),
        },
        {
          label: 'Тип операции',
          field: 'type_operation',
          // field: row => row.type_operation,
        },
        {
          label: 'Сумма',
          field: 'sum',
          // field: row => `${new Intl.NumberFormat('ru-RU').format(row.sum)}₽`,
        },
        {
          label: 'Время операции',
          field: row => row.action_date,
        },
        {
          label: 'Действия',
          field: 'action',
        },
      ],
      filters: {},
      defaultFilters: {
        organization_id: null,
        type_kkm: null,
        perPage: 20,
        detail_id: null,
      },
      organizations: {},
      details: {},
      loading: true,
      dayInfo: null,
      dayData: null,
      settings: {},
    }
  },
  computed: {
    availableDetails() {
      return _.filter(this.details, detail => detail.id === null || detail.type_kkm === this.filters.type_kkm)
    },
  },
  // watch: {
  //   filters: {
  //     deep: true,
  //     handler() {
  //       this.doApplyFilters()
  //     },
  //   },
  // },
  mounted() {
    const dayId = this.$route.params.id
    this.filters = { ...this.defaultFilters }
    Promise.all([
      this.loadDayInfo(dayId),
      this.loadDayData(dayId),
      this.loadOrganizations(),
      this.loadDetails(),
    ]).then(() => {
      this.loading = false
    })
  },
  beforeDestroy() {
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
  },
  methods: {
    formatDate(inputDate) {
      const date = new Date(inputDate)
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(date.getTime())) {
        return ' - '
      }

      return `${(date.getDate() + 1) <= 10 ? `0${date.getDate()}` : date.getDate()}.${(date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1)}.${date.getFullYear()}`
    },
    objectDelete(id) {
      this.$swal({
        title: '',
        text: 'Вы уверены что хотите удалить эту запись?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axiosIns({
            method: 'DELETE',
            url: `/cashiers/delete/${id}`,
          }).then(() => {
            this.loadDayData(this.$route.params.id)
            this.$swal({
              icon: 'success',
              title: 'Удалено!',
              text: '',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
      console.log(id)
    },
    doUpdateFilter(filter) {
      const mergedFilters = _.merge(this.filters, filter)
      this.filters = { ...mergedFilters }
      this.loadDayData(this.$route.params.id)
    },
    doApplyFilters() {
      const mergedFilters = _.merge(this.filters, { page: 1 })
      this.filters = { ...mergedFilters }
      this.loadDayData(this.$route.params.id)
    },
    doResetFilters() {
      this.filters = { ...this.defaultFilters }
      this.loadDayData(this.$route.params.id)
    },
    loadDayInfo(id) {
      const req = axiosIns({
        method: 'GET',
        url: `/cashiers/date/info/${id}`,
      })

      req.then(response => {
        this.dayInfo = response.data.data
        store.commit('app/UPDATE_CURRENT_PAGE_TITLE', this.formatDate(this.dayInfo.date))
      })

      return req
    },
    loadDayData(id, defaultFilters = null) {
      if (defaultFilters !== null) {
        this.defaultFilters = defaultFilters
      }

      return axiosIns({
        method: 'GET',
        url: `/cashiers/date/data/${id}`,
        params: _.merge(defaultFilters, this.filters),
      })
        .then(response => {
          const responseData = response.data.data
          this.$set(this, 'dayData', _.cloneDeep(response.data.data.data))

          this.settings.currentPage = responseData.current_page
          this.settings.total = responseData.total
          this.settings.to = responseData.to
          this.settings.from = responseData.from
          this.settings.perPage = responseData.per_page
          this.settings.lastPage = responseData.last_page
        })
    },
    loadOrganizations() {
      const req = axiosIns({
        method: 'GET',
        url: '/organizations/short-list',
      })

      req.then(response => {
        const data = [{ id: null, name: ' — ' }]
        data.push(...response.data.data)
        this.organizations = data
      })

      return req
    },
    loadDetails() {
      const req = axiosIns({
        method: 'GET',
        url: '/cashiers/details',
      })

      req.then(response => {
        const data = [{ id: null, name: ' — ' }]
        data.push(...response.data.data)
        this.details = data
      })

      return req
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
